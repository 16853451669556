<template>
  <div class="flex items-center justify-start w-full border-b border-primary_800 my-2">
    <div v-for="(tab, index) in props.tabs" :key="index">
      <div v-if="!tab.isHidden" class="flex flex-col items-center tabHolder">
        <div
          class="flex items-center justify-center px-3 min-w-[48px] h-[28px] cursor-pointer"
          @click="tab.action"
        >
          <p
            class="text-[12px] font-medium"
            :class="tab.active ? 'text-neutral_50' : 'text-primary_500 '"
          >
            {{ tab.text }}
          </p>
        </div>
        <div
          class="h-[4px] rounded-full w-[40px] tabSelector"
          :class="tab.active ? 'bg-primary_800' : ''"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { tabItem } from '~/interfaces';

const props = defineProps({
  tabs: {
    required: true,
    type: Array as PropType<Array<tabItem>>
  }
});
</script>
<style scoped lang="scss">
@media (min-width: 481px) {
  .tabHolder:hover {
    .tabSelector {
      background: #83ba40;
    }
  }
}
</style>
